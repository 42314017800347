.ToolsContainer_absoluteObject__3-PKv {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

@font-face {
  font-family: 'Avenir';
  src: url(/static/media/avenir.b9943993.woff2) format('woff2'), url(/static/media/avenir.9e63c621.woff) format('woff');
  font-style: normal;
}

.Layout_layout__XgnM0 {
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none;
}

.Layout_layoutZone__3k6ig {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  pointer-events: auto;
}

.Layout_layoutZoneBottomLeft__2cM9d {
  margin-top: 10px;
}

@media screen and (max-height: 548px) {
  .Layout_layoutZoneBottomLeft__2cM9d {
    flex-direction: row;
  }

  .Layout_layoutZoneBottomLeft__2cM9d > div > button {
    margin-right: 15px;
  }
}

.Layout_layoutZone__3k6ig > button {
  flex-shrink: 0;
}

.Layout_layoutZoneTopRight__MCbBB {
  position: absolute;
  top: 15px;
  right: 15px;
}

.Layout_layoutZoneTopRight__MCbBB > div,
.Layout_layoutZoneBottomLeft__2cM9d > div {
  margin-bottom: 15px;
}

.Layout_layoutZoneTopRight__MCbBB > div:last-child,
.Layout_layoutZoneBottomLeft__2cM9d > div:last-child {
  margin-bottom: 0;
}

.Layout_layoutZoneTopLeft__1Co4a {
  position: absolute;
  top: 15px;
  left: 15px;
}

.Layout_layoutZoneBottomLeft__2cM9d {
  position: absolute;
  top: 360px;
  left: 65px;
}

.Layout_hiddenZoneContainer__3tfl- {
  display: none;
}

.App_app__3vWKC {
  width: 100%;
  height: 100%;
  background: var(--white-background);
  touch-action: none;
  -webkit-tap-highlight-color: var(--gray-webkit);
}

.App_progress__2OVDz {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1001;
}

.App_renderers-container__j1iyS {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.App_toolsContainer__THNzp {
  width: 100vw;
  height: 100vh;
  background-color: var(--red-backgroundColorDraggableObject);
}

.App_appVersion__1IDu0 {
  position: absolute;
  bottom: 30px;
  left: 65px;
  pointer-events: none;
  color: transparent;
}

.App_appVersion__1IDu0::selection {
  color: var(--black-general);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  position: fixed;
}

body {
  padding: 0;
  margin: 0;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: inherit;
}

#root {
  width: 100vw;
  height: 100vh;
}

:root {
  --white-background: #f2f7fa;
  --white-hourglassProgress: #ececec;
  --white-backgroundRendererComp: #e0edf4;
  --white-general: #fff;
  --white-iosimPlusButton: #f4f4f5;
  --white-backgroundModalButton: #f3f3f3;
  --white-backgroundRadioButton: rgb(233, 233, 233);
  --white-groupedDropDownList: rgb(215, 215, 215);
  --white-timelapseActiveScanBackground: #f4f4f4;

  --black-general: #000;
  --black-groupedDropDownList: #222;
  --black-boxShadowTimelapseDropDown: rgba(0, 0, 0, 0.17);
  --black-timelapseActiveScanBoxShadow: rgba(0, 0, 0, 0.06);
  --black-dropShadow5D: rgba(0, 0, 0, 0.7);
  --black-stripButton: rgba(0, 0, 0, 0.11);
  --black-boxShadowIosimPlusButton: rgba(0, 0, 0, 0.15);
  --black-textDisabledExplorer: rgba(0, 0, 0, 0.25);
  --black-boxShadowGroupedDropDownList: rgba(0, 0, 0, 0.04);
  --black-boxShadowImageFramesContainer: rgba(5, 5, 5, 0.01);
  --black-backgroundButtonModal: rgba(0, 0, 0, 0.6);
  --black-boxShadowGroupedDropDownCard: rgba(0, 0, 0, 0.02);
  --black-boxShadowButtonGroupedDropDown: rgba(0, 0, 0, 0.05);
  --black-boxShadowButtonHoverGroupedDropDown: rgba(0, 0, 0, 0.14);
  --black-timelapseTimeColor: rgba(0, 0, 0, 0.63);
  --black-timelapseCompareButtonColor: rgba(74, 75, 77, 0.5);
  --black-timelapseCompareButtonBorder: rgba(147, 149, 152, 0.5);

  --blue-light: #33bdfa;
  --blue-general: #0067ac;
  --blue-PluginControlButton: #33bdfa;
  --blue-listItemHoverDropDown: #03a9f4a3;
  --blue-borderImageFramesContainer: #03aeef;
  --blue-borderImageManipulationSlider: #00adef;
  --blue-backgroundRenderer: #cadeeb;
  --blue-timelapseDropDown: #0286b8;
  --blue-timelapseDropDownButton: #06b4ff;
  --blue-backgroundPluginControlButton: rgba(41, 198, 255, 0.322);
  --blue-boxShadowGroupedDropDownList: rgba(59, 153, 252, 0.7);
  --blue-backgroundTimelapseDropDown: rgba(0, 173, 239, 0.52);
  --blue-backgroundHoverTimelapseDropDown: rgba(0, 105, 146, 0.52);
  --blue-borderCheckbox: rgba(51, 189, 250, 0.5);
  --blue-backgroundMarker: lightskyblue;
  --blue-borderBottomTable: darkblue;
  --blue-backgroundColorRadioButtonGroup: rgb(3, 171, 239);
  --blue-backgroundColorSelectedTable: rgb(37, 97, 168);
  --blue-backgroundColorHeaderTable: rgb(105, 157, 220);
  --blue-timelapseActiveScan: #009ace;
  --blue-timelapseTitleBackground: #41c1f0;
  --blue-expandCollapseIconBorder: #00adef;
  --blue-textDisabledExplorer: #b5d3e7;

  --gray-borderIosimPlusButton: #939598;
  --gray-borderBottomIosimPlusButton: #c9cacb;
  --gray-modalMessage: #3e3d40;
  --gray-boxShadowModalButton: #939598;
  --gray-GroupedDropDownList: #444;
  --gray-borderGroupedDropDownList: #aaa;
  --gray-groupedDropDownListDisabledSelectCss: gray;
  --gray-backgroundImageGroupedDropDownList: #d3d3d3;
  --gray-borderColorGroupedDropDownList: #888;
  --gray-borderImageFrames: #c3c3c3;
  --gray-backgroundImageFrames: #ececec;
  --gray-StripMenuDisabledExplorer: #9e9e9e;
  --gray-backgroundColorTable: #f2f2f2;
  --gray-backGroundTimelapseDropDown: #e9ecef;
  --gray-buttonTimelapseDropDown: #e2e2e2;
  --gray-webkit: rgba(144, 144, 144, 0.5);
  --gray-stripMenuTitle: rgba(120, 151, 172, 0.37);
  --gray-boxShadowTimelapseDropDownButton: rgba(255, 255, 255, 0.25);
  --gray-RadioButtonGroupTitle: rgb(79, 79, 79);
  --gray-borderRadioButtonGroup: darkgray;
  --gray-backgroundRadioButtonGroup: lightgray;
  --gray-RadioButtonGroup: rgb(91, 91, 91);
  --gray-timelapseScanBorder: rgba(230, 230, 230, 0.51);
  --gray-timelapseCurrentScanBackground: #f3f3f3;

  --silver: silver;

  --brown: #968c7d;

  --yellow: yellow;

  --red-backgroundColorDraggableObject: red;
  --red-markerHover: tomato;
  --red-deleteButton: rgb(211, 37, 54);
  --red-deleteButtonHover: rgb(156, 27, 39);

  --green-submitButton: rgb(36, 151, 62);
  --green-submitButtonHover: rgb(27, 116, 47);
}

