*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  position: fixed;
}

body {
  padding: 0;
  margin: 0;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: inherit;
}

#root {
  width: 100vw;
  height: 100vh;
}
